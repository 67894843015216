<template lang="pug">
div(class='flex items-center')
  div(class='text-dark font-bold')
    span(v-if='type === "SITE"') Guaranteed Fit
    span(v-else) Call For Guaranteed Fit
  Tooltip(mode='click' :is-full-width='!$device.isDesktop')
    InfoIcon(class='w-4 h-4 cursor-pointer text-action fill-current align-middle ml-1 focus:outline-none')
    template(#text)
      span(v-if='type === "SITE"') We guarantee this part will fit your vehicle or you can return it for free.
      span(v-else) This part is designed for your vehicle, but its fit depends on compatibility with other products before we can guarantee it.
      UniversalLink(link='/guaranteed-fit/' :open-new-window='true' class='ml-2') Learn more
div(v-if='type === "PHONE"' class='pb-3 leading-3')
  UniversalLink(link='PHONE' class='text-sm font-bold text-black underline') {{ $sitewideConfig.config.phoneGeneral }}
</template>

<script setup lang="ts">
import InfoIcon from '@/assets/info.svg?component'
const { $sitewideConfig, $device } = useNuxtApp()
const { type = 'SITE' } = defineProps<{
  type: 'SITE' | 'PHONE'
}>()
</script>
